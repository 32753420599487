import styled from "styled-components";
import Header from "../Header";
// import track from "../utils/track";
// import daddySays from "../img/daddy-says-1220x1220.jpg";

const Home = ({ tracking }) => {
  return (
    <>
      <Header tracking={tracking} hideSubscribe={false} />
      {/*<ReleaseCover
        onClick={() => track(tracking, "PreSave")}
        href="https://smarturl.it/Fem_DaddySays_pre"
      >
        <ReleaseBlurb>
          out 4th August&nbsp;&nbsp;
          <br />
          &nbsp;pre-save on Spotify
        </ReleaseBlurb>
  </ReleaseCover>*/}
      <Video
        src="https://youtube.com/embed/XpM6-9WSfT0"
        title="Toolish YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen=""
      ></Video>

      <Video
        src="https://youtube.com/embed/nFdqAf2aeyU"
        title="Depleted YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen=""
      ></Video>

      <Video
        src="https://youtube.com/embed/r2ZIIN9GUu8"
        title="Be Alone YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen=""
      ></Video>
      <Video
        src="https://youtube.com/embed/B1EKF-yZpVI"
        title="Daddy Says YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen=""
      ></Video>
      {/*<SmallAudio
        src="https://bandcamp.com/EmbeddedPlayer/track=2857700952/size=large/bgcol=333333/linkcol=4ec5ec/tracklist=false/artwork=small/transparent=true/"
        seamless
      >
        <a href="https://femegades.bandcamp.com/track/be-alone">
          Be Alone by Femegades
        </a>
</SmallAudio>*/}
      <Video
        src="https://youtube.com/embed/RRYCaSrWT78"
        title="Speechless YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen=""
      ></Video>
      <Video
        src="https://www.youtube.com/embed/lm3IH9ZL2TQ"
        title="Pornsick YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen=""
      ></Video>
    </>
  );
};

/*
const ReleaseCover = styled.a`
  background-image: url(${daddySays});
  background-size: cover;
  background-repeat: no-repeat;
  margin: 32px auto 0;
  text-align: right;
  text-decoration: none;
  color: black;
  height: 300px;
  width: 300px;

  @media (min-width: 532px) {
    height: 500px;
    width: 500px;
  }

  @media (min-width: 768px) {
    width: 700px;
    height: 700px;
  }

  & :hover {
    text-decoration: underline;
  }
`;

const ReleaseBlurb = styled.span`
  position: relative;
  top: 52px;
  right: 8px;
  text-decoration: none;
  font-weight: bold;
  color: black;
  font-size: 18px;
  background-color: #f19d55;
  padding: 0 4px 4px;

  @media (min-width: 532px) {
    top: 110px;
    right: 20px;
    font-size: 18px;
  }

  @media (min-width: 768px) {
    top: 140px;
    right: 30px;
    font-size: 22px;
  }
`;
*/

const IFrame = styled.iframe`
  text-align: center;
  margin: 0 auto;
  border: none;
  margin-top: 16px;
  width: 100%;

  @media (min-width: 768px) {
    width: 700px;
  }
`;

const Video = styled(IFrame)`
  height: 210px;

  @media (min-width: 532px) {
    height: 297px;
  }

  @media (min-width: 768px) {
    height: 394px;
  }
`;

/*
const SmallAudio = styled(IFrame)`
  height: 120px;
`;
*/

export default Home;
