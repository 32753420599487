import { filterContent, getCMSData } from "./utils";

export const getGigs = async () => {
  const allGigs = await getCMSData()
    .then((response) => response.json())
    .then((data) => ({
      gigs: data.items.filter(filterContent("gigs")),
      assets: data.includes.Asset,
    }));

  return allGigs;
};
