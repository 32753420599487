import styled from "styled-components";
import Banner from "./Banner";
import Footer from "./Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ReactGA from "react-ga4";

import img from "./img/bg.jpg";
import Subscribe from "./Components/Subscribe";
import Live from "./Components/Live";
import Home from "./Components/Home";

ReactGA.initialize("G-CBE6DRXW7C");

const App = () => {
  ReactGA.send(window.location.pathname + window.location.search);

  return (
    <Router>
      <Content>
        <Banner />
        <Routes>
          <Route path="/subscribe" element={<Subscribe tracking={ReactGA} />} />
          <Route path="/live" element={<Live tracking={ReactGA} />} />
          <Route path="/" element={<Home tracking={ReactGA} />} />
        </Routes>
        <Footer />
      </Content>
    </Router>
  );
};

const Content = styled.div`
  background-image: url(${img});
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  background-position-x: -860px;
  background-position-y: 100px;
  background-repeat: no-repeat;

  @media (min-width: 1200px) {
    background-position-x: 0;
  }
`;

export default App;
