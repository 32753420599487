import {
  CMS_ACCESS_TOKEN,
  CMS_API_BASE_URL,
  CMS_ENVIRONMENT_ID,
  CMS_SPACE_ID,
} from "./config";

export const filterContent = (type) => (item) =>
  item.sys.contentType.sys.id === type;

export const getCMSData = async () =>
  await fetch(
    `${CMS_API_BASE_URL}/spaces/${CMS_SPACE_ID}/environments/${CMS_ENVIRONMENT_ID}/entries`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${CMS_ACCESS_TOKEN}`,
      },
    }
  );
