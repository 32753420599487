import React, { useEffect, useState } from "react";
import Header from "../Header";
import styled from "styled-components";
import track from "../utils/track";
import { getGigs } from "../cms/gigs";

const gigsCompare = (a, b) => {
  return new Date(a.fields.date) - new Date(b.fields.date);
};

const loadAsset = (poster, assets) => {
  if (!poster || !assets) return null;

  const assetId = poster.sys.id;
  const asset = assets.find((foo) => foo.sys.id === assetId);
  if (!asset) return null;
  return "https:" + asset.fields.file.url;
};

const Live = ({ tracking }) => {
  const now = new Date();
  const [gigs, setGigs] = useState();
  const [assets, setAssets] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const data = await getGigs();
      setGigs(data.gigs);
      setAssets(data.assets);
    };
    fetchData().catch(console.error);
  }, []);

  const upcoming =
    gigs && gigs.filter(({ fields }) => new Date(fields.date) > now);
  const pastEvents =
    gigs && gigs.filter(({ fields }) => new Date(fields.date) <= now);

  let stripe = false;
  return (
    <>
      <Header tracking={tracking} hideSubscribe={false} />
      <LiveTable>
        <h2>Upcoming</h2>
        {upcoming && upcoming.length > 0 ? (
          upcoming.sort(gigsCompare).map(({ fields, sys }) => {
            return <GigRow gig={fields} key={sys.id} tracking={tracking} />;
          })
        ) : (
          <i>Check back soon for an update</i>
        )}
        <h2>Past Events</h2>
        {pastEvents &&
          pastEvents
            .sort(gigsCompare)
            .reverse()
            .map(({ fields, sys }) => {
              const posterUrl = loadAsset(fields.poster, assets);
              stripe = !stripe;
              return (
                <GigRow
                  gig={fields}
                  past={true}
                  key={sys.id}
                  tracking={tracking}
                  posterUrl={posterUrl}
                  stripe={stripe}
                />
              );
            })}
      </LiveTable>
    </>
  );
};

const GigRow = ({ gig, past, tracking, posterUrl, stripe }) => {
  const { date, venue, city, country, setlist, tickets } = gig;
  const gigDate = new Date(date);
  const trackingCode = `${gigDate
    .toLocaleDateString("en-GB", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    })
    .replaceAll("/", "")}${venue.substring(0, 3)}`;
  const [setlistExpand, setSetlistExpand] = useState();

  return (
    <>
      <Row>
        <Cell className={stripe ? "striped" : "striped-alt"}>
          {gigDate.toLocaleDateString("en-GB", {
            weekday: "short",
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </Cell>
        <Cell
          className={stripe ? "striped" : "striped-alt"}
        >{`${venue} - ${city}, ${country}`}</Cell>
        <Cell className={stripe ? "striped" : "striped-alt"}>
          {!past && tickets && (
            <>
              [
              <a
                href={`${tickets}`}
                onClick={() => track(tracking, trackingCode)}
              >
                tickets
              </a>
              ]
            </>
          )}
          {setlist && (
            <>
              [
              <SetListButton
                onClick={() => {
                  track(tracking, `${trackingCode}-setlist`);
                  setSetlistExpand(!setlistExpand);
                }}
              >
                setlist
              </SetListButton>
              ]
            </>
          )}
        </Cell>
        <br />
      </Row>
      {setlist && setlistExpand ? (
        <Setlist>
          {setlist.map((song) => {
            const toDisplay = song.split(" (");
            return (
              <div key={song}>
                <span>{toDisplay[0]}</span>
                {toDisplay[1] && (
                  <span
                    style={{ fontWeight: "normal" }}
                  >{` (${toDisplay[1]}`}</span>
                )}
              </div>
            );
          })}
        </Setlist>
      ) : null}
      {posterUrl && (
        <Row>
          <Cell>
            <img
              width="100%"
              alt={`${date} ${venue} ${city} ${country}`}
              src={posterUrl}
            />
          </Cell>
        </Row>
      )}
    </>
  );
};

const LiveTable = styled.div`
  padding: 32px 0;
  color: white;
  font-size: 18px;
`;

const Row = styled.div``;

const Cell = styled.div`
  display: inline-block;
  padding: 4px 8px;

  &.striped {
    background-color: #4e0000;
    opacity: 0.85;
  }

  &.striped-alt {
    background-color: #bf0101;
    opacity: 0.85;
  }

  a {
    color: white;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const SetListButton = styled.button`
  background-color: transparent;
  border: 0;
  color: white;
  cursor: pointer;
  font-size: 18px;
  margin: 0;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }
`;

const Setlist = styled.div`
  display: block;
  font-weight: bold;
`;

export default Live;
