import React from "react";
import styled from "styled-components";
import "typeface-libre-baskerville";

import img from "./img/band-horiz.jpg";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterText>
        contact: <a href="mailto:femegades@gmail.com">femegades@gmail.com</a>
      </FooterText>
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  height: 332px;
  background-image: url(${img});
  background-color: black;
  background-repeat: no-repeat;
  background-position: center bottom;
  text-align: center;
  margin-top: auto;
  margin-top: 16px;
`;

const FooterText = styled.div`
  font-family: Libre Baskerville;
  font-size: 18px;
  color: white;
  font-weight: bold;
  padding: 20px 0;

  a {
    color: white;
    text-decoration: none;
  }

  @media (min-width: 768px) {
    font-size: 22px;
  }
`;

export default Footer;
