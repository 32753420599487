import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "typeface-roboto";
import ReactGA from "react-ga4";
import track from "./utils/track";
import { getBanner } from "./cms/banner";

ReactGA.initialize("G-CBE6DRXW7C");

const Banner = () => {
  const [banner, setBanner] = useState();

  useEffect(() => {
    const fetchData = async () => {
      setBanner(await getBanner());
    };
    fetchData().catch(console.error);
  }, []);

  return banner ? (
    <BannerText
      onClick={() => track(ReactGA, "Banner")}
      href={banner.fields.link}
    >
      {banner.fields.text}
      <BannerCTA>{banner.fields.cta}</BannerCTA>
    </BannerText>
  ) : null;
};

const BannerText = styled.a`
  background-color: orange;
  color: black;
  font-size: 22px;
  text-decoration: none;
  padding: 16px 0;
  line-height: 150%;
`;

const BannerCTA = styled.span`
  background-color: black;
  color: orange;
  font-family: Roboto;
  margin-left: 10px;
  padding: 4px;
`;

export default Banner;
